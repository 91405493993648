import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

const TourPage = () => {
    return (
        <Layout>
            <SEO title="Fleet" description={"fleet"} />
            <main>
                <Wrapper>
                     {/*place here the component*/}
                </Wrapper>
            </main>
        </Layout>
    );
};

const Wrapper = styled.div`
    //height: 100vh;
    //display: flex;
    //justify-content: center;
    //align-items: center;
`;

export default TourPage;
